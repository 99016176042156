.my-projects {
    padding-bottom: 5vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 5vw; */
}



.catagory {
    width: 100%;
    height: auto;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vw;
    padding-top: 5vw;
}

.catagory .slider-heading {
    padding: 0 5vw;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.catagory .slider-heading h2 {
    width: 90%;
    text-align: left;
} 


/*------------------- slider -----------------
----------*/

.my-slider {
    width: 100%;
    height: 100%;
    padding: 0 5vw;
    z-index: 2;
}

  
.my-project-slide {
    height: 20vw;
    border-radius: 1vw;
    text-align: left;
    margin-bottom: 3vw;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    padding: .7vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    transition: width .3s ease-in;
}

#slide-bg {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top right;
}

.catagory .swiper-slide-active {
    width: 33vw !important;
}

.my-project-slide .orangearrow {
    width: 3vw;
    height: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    line-height: 100%;
    border: 1px solid #FD853A;
    color: #FD853A;
    border-radius: 50%;
    transition: all .2s;
}

.my-project-slide:hover .orangearrow {
    background: #FD853A;
    color: white;
}

.my-project-slide .detail {
    width: 100%;
    height: 3.5vw;
    padding: 1vw;
    overflow-y: scroll;
    transition: height .2s ease-in;
}

.my-project-slide .detail::-webkit-scrollbar {
    display: none;
}

.my-project-slide:hover .detail {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(12.5px);
    border-radius: 1vw;
    height: 5.5vw;
}

.my-project-slide .detail h4 {
    font-family: Lufga-bold;
    font-size: 2vw;
    line-height: 140%;
    color: #E4E7EC;
}

.my-project-slide .detail p {
    color: #E4E7EC;
    font-size: .8vw;
    font-weight: 400;
}


.swiper-pagination-bullet {
    transition: width .2s;
    transform-origin: center;
    background: #E4E7EC;
    opacity: 1;
    width: .7vw;
    height: .7vw;
}
  
.swiper-pagination-bullet-active {
    width: 3vw;
    border-radius: 20vw;
    background:  #FD853A;
}

.autoplay-progress {
    position: absolute;
    right: 2vw;
    bottom: 0vw;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--swiper-theme-color);
  }
  
  .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #FD853A;
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }

@media(max-width:1600px) {

        
.catagory .swiper-slide-active {
    width: 43vw !important;
}

    .my-project-slide .orangearrow {
        width: 4vw;
        height: 4vw;
        font-size: 2.5vw;
    }
    
    .my-project-slide:hover .orangearrow {
        background: #FD853A;
        color: white;
    }

    .my-project-slide .detail h4 {
        font-size: 3vw;
    }

    
.my-project-slide:hover .detail {
    height: 7vw;
}

    .my-project-slide .detail {
        height: 5.05vw;
    }

    .my-project-slide .detail p {
        font-size: 1.1vw;
    }

    .my-project-slide {
        height: 23vw;
        margin-bottom: 4vw;
    }

    .swiper-pagination-bullet {
        width: .9vw;
        height: .9vw;
    }
      
    .swiper-pagination-bullet-active {
        width: 3vw;
    }
}

@media(max-width:980px) {
    .catagory .swiper-slide-active {
        width: 43vw !important;
    }

    .my-project-slide {
        height: 27vw;
    }

    .catagory {
        padding-top: 9vw;
    }

}

@media(max-width:600px) {

    .my-projects {
        padding-top: 15vw;
    }

    .catagory .swiper-slide-active {
        width: 90% !important;
    }

    .my-slider {
        padding-bottom: 15vw;
    }

    .catagory {
        padding-top: 3vw;
        /* gap: 5vw; */
    }
    
    .catagory .slider-heading {
        padding: 0 5vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3vw;
        justify-content: space-between;
        position: relative;
    }
    
    .catagory .slider-heading h2 {
        width: 90%;
        text-align: left;
    } 

    .catagory .slider-heading .btn1 {
        position: absolute;
        top: 85vw;
    }
    .my-project-slide {
        height: 55vw;
        border-radius: 3vw;
        margin-bottom: 0vw;
        padding: 2vw;
    }

    .my-project-slide .orangearrow {
        width: 7vw;
        height: 7vw;
        font-size: 6vw;
        background: white;
    }
    
    .my-project-slide:hover .orangearrow {
        background: #FD853A;
        color: white;
    }

    .my-project-slide .detail h4 {
        font-size: 5.5vw;
    }

    
    .my-project-slide:hover .detail {
        height: 14vw;
        border-radius: 3vw;
    }

    .my-project-slide .detail {
        height: 9vw;
        padding: 2vw;
    }

    .my-project-slide .detail p {
        font-size: 2.5vw;
    }

    .swiper-pagination-fraction, 
    .swiper-pagination-custom, 
    .swiper-horizontal > .swiper-pagination-bullets, 
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 7vw;
    }

    .swiper-pagination-bullet {
        width: 1.9vw;
        height: 1.9vw;
    }
      
    .swiper-pagination-bullet-active {
        width: 6vw;
    }

    .autoplay-progress {
        position: absolute;
        right: 2vw;
        bottom: 0vw;
        z-index: 10;
        width: 48px;
        height: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: var(--swiper-theme-color);
      }
      
      .autoplay-progress svg {
        --progress: 0;
        position: absolute;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        stroke-width: 4px;
        stroke: #FD853A;
        fill: none;
        stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
        stroke-dasharray: 125.6;
        transform: rotate(-90deg);
      }
}

@media(max-width:300px) {
    .catagory .swiper-slide-active {
        width: 100% !important;
    }
}


/*---------- catagory1 slider image maintainance --------
-----------------*/


.catagory1 .my-project-slide:nth-child(1) {
    background: linear-gradient(207deg,
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/xavier.jpg);
}


.catagory1 .my-project-slide:nth-child(2) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/guardian.jpg), #F2F4F7;
}

.catagory1 .my-project-slide:nth-child(3) {
    background:linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/medco.jpg), #F2F4F7;
}

.catagory1 .my-project-slide:nth-child(4) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/social\ bot.jpg), #F2F4F7;
}

.catagory1 .my-project-slide:nth-child(5) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/blink.jpg), #F2F4F7;
}

.catagory1 .my-project-slide:nth-child(6) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/tele\ sniper.jpg), #F2F4F7;
}

.catagory1 .my-project-slide:nth-child(7) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/tg-ai.jpg), #F2F4F7;
}

.catagory1 .my-project-slide:nth-child(8) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/shocky.jpg), #F2F4F7;
}


/*---------- catagory2 slider image maintainance --------
-----------------*/


.catagory2 .my-project-slide:nth-child(1) {
    background: linear-gradient(207deg,
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/oryx.jpg);
}

.catagory2 .my-project-slide:nth-child(2) {
    background:linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/mad\ bee.jpg), #F2F4F7;
}

.catagory2 .my-project-slide:nth-child(3) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/nfa2.0.jpg), #F2F4F7;
}

.catagory2 .my-project-slide:nth-child(4) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/yoshi.jpg), #F2F4F7;
}

.catagory2 .my-project-slide:nth-child(5) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/real\ pepe.jpg), #F2F4F7;
}

.catagory2 .my-project-slide:nth-child(6) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/bobo\ bear.jpg), #F2F4F7;
}

.catagory2 .my-project-slide:nth-child(7) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/drakey.jpg), #F2F4F7;
}

.catagory2 .my-project-slide:nth-child(8) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/pepoodle.jpg), #F2F4F7;;
}

.catagory2 .my-project-slide:nth-child(9) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/bahamas.jpg), #F2F4F7;;
}

/*---------- catagory3 slider image maintainance --------
-----------------*/


.catagory3 .my-project-slide:nth-child(1) {
    background: linear-gradient(207deg,
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/republic.jpg);
}

.catagory3 .my-project-slide:nth-child(2) {
    background:linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/alien.jpg), #F2F4F7;
}

.catagory3 .my-project-slide:nth-child(3) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/bitcoin.jpg), #F2F4F7;
}

.catagory3 .my-project-slide:nth-child(4) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/ghost.jpg), #F2F4F7;
}

.catagory3 .my-project-slide:nth-child(5) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/mrbridge.jpg), #F2F4F7;
}

.catagory3 .my-project-slide:nth-child(6) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/snake.jpg), #F2F4F7;
}

.catagory3 .my-project-slide:nth-child(7) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/xai.jpg), #F2F4F7;
}

.catagory3 .my-project-slide:nth-child(8) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/harry.jpg), #F2F4F7;
}

/*---------- catagory4 slider image maintainance --------
-----------------*/


.catagory4 .my-project-slide:nth-child(1) {
    background: linear-gradient(207deg,
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/twoland.jpg);
}

.catagory4 .my-project-slide:nth-child(2) {
    background:linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/ggbond.jpg), #F2F4F7;
}

.catagory4 .my-project-slide:nth-child(3) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/siphon.jpg), #F2F4F7;
}

.catagory4 .my-project-slide:nth-child(4) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/antis\ inu.jpg), #F2F4F7;
}

.catagory4 .my-project-slide:nth-child(5) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/boostfx.jpg), #F2F4F7;
}

.catagory4 .my-project-slide:nth-child(6) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/chaintools.jpg), #F2F4F7;
}

.catagory4 .my-project-slide:nth-child(7) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/telebot.jpg), #F2F4F7;
}

.catagory4 .my-project-slide:nth-child(8) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/dashboard\ chart.png), #F2F4F7;;
}

/*---------- catagory5 slider image maintainance --------
-----------------*/


.catagory5 .my-project-slide:nth-child(1) {
    background: linear-gradient(207deg,
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/ewin.jpg);
}

.catagory5 .my-project-slide:nth-child(2) {
    background:linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/redpepe.jpg), #F2F4F7;
}

.catagory5 .my-project-slide:nth-child(3) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/Hoppy.jpg), #F2F4F7;
}

.catagory5 .my-project-slide:nth-child(4) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/tadpole.jpg), #F2F4F7;
}

.catagory5 .my-project-slide:nth-child(5) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/wonka.jpg), #F2F4F7;
}

.catagory5 .my-project-slide:nth-child(6) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/shibanon.jpg), #F2F4F7;
}

.catagory5 .my-project-slide:nth-child(7) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/pogex.jpg), #F2F4F7;
}

.catagory5 .my-project-slide:nth-child(8) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/steiwe\ .jpg), #F2F4F7;;
}

.catagory5 .my-project-slide:nth-child(9) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/brrr.jpg), #F2F4F7;
}

.catagory5 .my-project-slide:nth-child(10) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/goat.jpg), #F2F4F7;
}

.catagory5 .my-project-slide:nth-child(11) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../../Components/Images/ggbond.jpg), #F2F4F7;
}