/*--------------------- hero-section ----------
----------*/

.hero-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vw;
  }
  
  .hero-section .intro {
    width: 100%;
  }
  
  .hero-section .intro .name {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    position: relative;
    transition: all .4s;
  }
  
  .hero-section .intro .name.active {
    opacity: 0;
    transform: translateY(20vw);
  }
  
  .hero-section .intro .name li {
    padding: .5vw 1.2vw;
    border: 1px solid black;
    border-radius: 20vw;
    transition: all .2s;
    background: transparent;
    cursor: pointer;
    animation: bounceInDown .8s;
  }

  .hero-section .intro .name h1 {
    animation: jackInTheBox .7s;
  }
  
  .hero-section .intro .name li:hover {
    background: rgba(0, 0, 0, .3);
  }
  
  .hero-section .intro .name .hair1 {
    position: absolute;
    top: -1.9vw;
    right: 45.5vw;
    width: 2vw;
    animation: fadeInRight 1.3s;
  }
  
  .hero-section .intro .name .hair2 {
    position: absolute;
    bottom: -3.7vw;
    left: 19vw;
    width: 5vw;
    animation: fadeInLeft 1.3s;
  }
  
  .hero-section .intro .experience {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5vw 5vw 0 5vw;
    transition: all 0.5s;
  }
  
  .hero-section .intro .experience.active {
    transform: translateY(-12vw);
  }
  
  .hero-section .intro .experience .left {
    width: 24%;
    text-align: left;
  }
  
  .hero-section .intro .experience .left img {
    width: 2vw;
  }
  
  .hero-section .intro .experience .right {
    width: max-content;
    text-align: right;
  }
  
  .hero-section .intro .experience .right .star {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .3vw;
    padding-bottom: .5vw;
  }
  
  .hero-section .intro .experience .right .star img {
    width: 1.5vw;
  }
  
  .hero-section .me {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: -3vw;
  }
  
  .hero-section .me .circle {
    width: 47vw;
    height: 23.3vw;
    z-index: 1;
  }
  
  .hero-section .me .mine {
    width: 32.5vw;
    height: 34vw;
    position: absolute;
    bottom: 0;
    z-index: 3;
  }
  
  .hero-section .me .celebration {
    width: 60vw;
    position: absolute;
    bottom: 0vw;
    transform: scale(0);
    transform-origin: bottom;
    transition: all .3s;
    z-index: 2;
  }
  
  .hero-section .me .celebration.active {
    transform: scale(1);
  }
  
  .hero-section .buttons {
    z-index: 4;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: .4vw;
    position: absolute;
    bottom: 2vw;
    border: .15vw solid #FFF;
    border-radius: 20vw;
    background: rgba(255, 255, 255, 0.10);
    -moz-backdrop-filter: blur(7.5px);
    -ms-backdrop-filter: blur(7.5px);
    -o-backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);
  }
  
  .hero-section .buttons li:nth-child(2) {
    font-size: 1.3vw;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: .7vw 2vw;
    color: #FFF;
    border: 0.1vw solid #D0D5DD;
    background: #FD853A;
    border-radius: 20vw;
    cursor: pointer;
    transition: all .1s ease-out;
  }
  
  .hero-section .buttons li:nth-child(1) {
    font-size: 1.3vw;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: #FFF;
    border-radius: 20vw;
    cursor: pointer;
    padding: .7vw 2vw;
    border: 0.1vw solid transparent;
    transition: all .1s ease-out;
    cursor: pointer;
  }
  
  .hero-section .buttons li:nth-child(1) img {
    display: none;
  }
  
  .hero-section .buttons li:nth-child(1):hover img {
    display: block;
  }
  
  .hero-section .buttons li:nth-child(1):hover {
    border: 0.1vw solid transparent;
    background: #FD853A;
    font-weight: 600;
  }
  
  .hero-section .buttons li:nth-child(1):hover + li:nth-child(2) {
    background: none;
    border: 0.1vw solid transparent;
    font-weight: 400;
  }
  
  .hero-section .buttons li:nth-child(1):hover + li:nth-child(2) img {
    display: none;
  }
  
  .hero-section .buttons li img {
    width: 2vw;
  }

@media(max-width:600px) {
    /*--------------------- hero-section 600px ----------
----------*/

.hero-section .intro .name.active {
    opacity: 0;
    transform: translateY(45vw);
  }
  
  .hero-section .intro .name li {
    padding: 1vw 5vw;
  }
  
  .hero-section .intro .name .hair1 {
    position: absolute;
    top: -4vw;
    right: 35.5vw;
    width: 5vw;
  }
  
  .hero-section .intro .name .hair2 {
    position: absolute;
    bottom: -7vw;
    left: 3.5vw;
    width: 8vw;
  }
  
  .hero-section .intro .experience {
    padding: 8vw 2vw 0 2vw;
    transition: all 0.4s;
  }
  
  .hero-section .intro .experience.active {
    transform: translateY(-30vw);
  }
  
  .hero-section .intro .experience .left {
    width: 36%;
  }
  
  .hero-section .intro .experience .left p {
    font-size: 1.9vw;
  }
  
  .hero-section .intro .experience .left img {
    width: 4vw;
  }
  
  .hero-section .intro .experience .right .star img {
    width: 3vw;
  }
  
  .hero-section .me {
    margin-top: 5vw;
  }
  
  .hero-section .me .circle {
    width: 90vw;
    height: 45vw;
  }
  
  .hero-section .me .mine {
    width: 70vw;
    height: 74vw;
  }
  
  .hero-section .me .celebration {
    width: 120%;
    transition: all .3s;
  }
  
  .hero-section .buttons {
    padding: 1vw;
    border: .2vw solid #FFF;
    bottom: 3.5vw;
    gap: 1.5vw;
  }
  
  .hero-section .buttons li:nth-child(2) {
    font-size: 4.2vw;
    padding: 1.5vw 3vw;
    color: #FFF;
    border: 0.15vw solid #D0D5DD;
  }
  
  .hero-section .buttons li:nth-child(1) {
    font-size: 4.2vw;
    padding: 1.5vw 3vw;
    border: 0.15vw solid transparent;
  }
  
  .hero-section .buttons li:nth-child(1):hover {
    border: 0.15vw solid transparent;
  }
  
  .hero-section .buttons li:nth-child(1):hover + li:nth-child(2) {
    border: 0.15vw solid transparent;
  }
  
  .hero-section .buttons li img {
    width: 5vw;
  }
}