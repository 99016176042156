.project-slider {
    width: 100%;
    height: auto;
    padding: 5vw 0vw;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vw;
}

.project-slider .project-slider-heading {
    padding: 0 5vw;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.project-slider .project-slider-heading h2 {
    width: 40%;
    text-align: left;
} 


/*------------------- slider -----------------
----------*/

.swiper {
    width: 100%;
    height: 100%;
    padding: 0 5vw;
    z-index: 2;
}


.swiper-project-slide:nth-child(1) {
    background: linear-gradient(207deg,
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/bahamas.jpg);
}

.swiper-project-slide:nth-child(2) {
    background:linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/real\ pepe.jpg), #F2F4F7;
}

.swiper-project-slide:nth-child(3) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/siphon.jpg), #F2F4F7;
}

.swiper-project-slide:nth-child(4) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/nfa.jpg), #F2F4F7;
}

.swiper-project-slide:nth-child(5) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/pepoodle.jpg), #F2F4F7;
}

.swiper-project-slide:nth-child(6) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/ghost.jpg), #F2F4F7;
}

.swiper-project-slide:nth-child(7) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/dashboard\ chart.png), #F2F4F7;
}

.swiper-project-slide:nth-child(8) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/alien.jpg), #F2F4F7;;
}

.swiper-project-slide:nth-child(9) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/drakey.jpg), #F2F4F7;
}

.swiper-project-slide:nth-child(10) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/bitcoin.jpg), #F2F4F7;
}

.swiper-project-slide:nth-child(11) {
    background: linear-gradient(207deg, 
    rgba(0, 0, 0, 0.00) 47.41%, 
    rgba(0, 0, 0, 0.36) 76.39%, 
    rgba(0, 0, 0, 0.50) 89.23%), 
    url(../Images/ggbond.jpg), #F2F4F7;
}
  
.swiper-project-slide {
    height: 20vw;
    border-radius: 1vw;
    text-align: left;
    margin-bottom: 3vw;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    padding: .7vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    transition: width .3s ease-in;
}

#slide-bg {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top right;
}

.project-slider .swiper-slide-active {
    width: 38vw !important;
}

.swiper-project-slide .orangearrow {
    width: 3vw;
    height: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    line-height: 100%;
    border: 1px solid #FD853A;
    color: #FD853A;
    border-radius: 50%;
    transition: all .2s;
}

.swiper-project-slide:hover .orangearrow {
    background: #FD853A;
    color: white;
}

.swiper-project-slide .detail {
    width: 100%;
    height: 4vw;
    padding: 1vw;
    overflow-y: scroll;
    transition: height .2s ease-in;
}

.swiper-project-slide .detail::-webkit-scrollbar {
    display: none;
}

.swiper-project-slide:hover .detail {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(12.5px);
    border-radius: 1vw;
    height: 10vw;
}

.swiper-project-slide .detail h4 {
    font-family: Lufga-bold;
    font-size: 2vw;
    line-height: 170%;
    color: #E4E7EC;
}

.swiper-project-slide .detail p {
    color: #E4E7EC;
    font-size: .8vw;
    font-weight: 400;
}


.swiper-pagination-bullet {
    transition: width .2s;
    transform-origin: center;
    background: #E4E7EC;
    opacity: 1;
    width: .7vw;
    height: .7vw;
}
  
.swiper-pagination-bullet-active {
    width: 3vw;
    border-radius: 20vw;
    background:  #FD853A;
}

.project-slider .catagories {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0vw 18vw;
}

.project-slider .catagories a p {
    background: #F2F4F7;
    padding: 1vw 2vw;
    border-radius: 20vw;
    color: black;
    transition: all .3s;
}

.project-slider .catagories a p:hover {
    background: #344054;
    color: #F2F4F7;
}

.autoplay-progress {
    position: absolute;
    right: 2vw;
    bottom: 0vw;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--swiper-theme-color);
  }
  
  .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #FD853A;
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }

@media(max-width:1600px) {

        
.project-slider .swiper-slide-active {
    width: 43vw !important;
}

    .swiper-project-slide .orangearrow {
        width: 4vw;
        height: 4vw;
        font-size: 2.5vw;
    }
    
    .swiper-project-slide:hover .orangearrow {
        background: #FD853A;
        color: white;
    }

    .swiper-project-slide .detail h4 {
        font-size: 3vw;
    }

    
.swiper-project-slide:hover .detail {
    height: 13vw;
}

    .swiper-project-slide .detail {
        height: 6vw;
    }

    .swiper-project-slide .detail p {
        font-size: 1.1vw;
    }

    .swiper-project-slide {
        height: 23vw;
        margin-bottom: 4vw;
    }

    .swiper-pagination-bullet {
        width: .9vw;
        height: .9vw;
    }
      
    .swiper-pagination-bullet-active {
        width: 3vw;
    }
}

@media(max-width:980px) {
    .project-slider .swiper-slide-active {
        width: 43vw !important;
    }

    .swiper-project-slide {
        height: 27vw;
    }

    .project-slider .catagories {
        padding: 0 15vw;
    }

    .autoplay-progress {
        right: 0vw;
    }

}

@media(max-width:600px) {

    .project-slider .swiper-slide-active {
        width: 90% !important;
    }

    .swiper {
        padding-bottom: 15vw;
    }

    .project-slider {
        padding: 10vw 0vw;
        padding-bottom: 20vw;
        gap: 5vw;
    }
    
    .project-slider .project-slider-heading {
        padding: 0 5vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3vw;
        justify-content: space-between;
        position: relative;
    }
    
    .project-slider .project-slider-heading h2 {
        width: 90%;
        text-align: center;
    } 

    .project-slider .project-slider-heading .btn1 {
        position: absolute;
        top: 85vw;
    }
    .swiper-project-slide {
        height: 55vw;
        border-radius: 3vw;
        margin-bottom: 0vw;
        padding: 2vw;
    }

    .swiper-project-slide .orangearrow {
        width: 7vw;
        height: 7vw;
        font-size: 6vw;
        background: white;
    }
    
    .swiper-project-slide:hover .orangearrow {
        background: #FD853A;
        color: white;
    }

    .swiper-project-slide .detail h4 {
        font-size: 5.5vw;
    }

    
    .swiper-project-slide:hover .detail {
        height: 26vw;
        border-radius: 3vw;
    }

    .swiper-project-slide .detail {
        height: 10vw;
        padding: 2vw;
    }

    .swiper-project-slide .detail p {
        font-size: 2.5vw;
    }

    .swiper-pagination-fraction, 
    .swiper-pagination-custom, 
    .swiper-horizontal > .swiper-pagination-bullets, 
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 7vw;
    }

    .swiper-pagination-bullet {
        width: 1.9vw;
        height: 1.9vw;
    }
      
    .swiper-pagination-bullet-active {
        width: 6vw;
    }

    
    .project-slider .catagories {
        flex-wrap: wrap;
        gap: 4vw;
        justify-content: center;
        padding: 5vw;
        padding-top: 10vw;
    }

    .project-slider .catagories a p {
        padding: 2vw 5vw;
    }

    .autoplay-progress {
        position: absolute;
        right: 2vw;
        bottom: 0vw;
        z-index: 10;
        width: 48px;
        height: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: var(--swiper-theme-color);
      }
      
      .autoplay-progress svg {
        --progress: 0;
        position: absolute;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        stroke-width: 4px;
        stroke: #FD853A;
        fill: none;
        stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
        stroke-dasharray: 125.6;
        transform: rotate(-90deg);
      }
}

@media(max-width:300px) {
    .project-slider .swiper-slide-active {
        width: 100% !important;
    }
}